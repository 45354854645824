import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpErrorResponse, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { CaseSmsFollower } from './case.sms-follower.model';
import { PreparedMessage } from './prepared-message.model';
import { NOTIFICATION_API_URL, DataService } from '../data.service';
import { SurgeryBlockSubscriber, GetSurgeryCaseSmsFollowersResponse, ProviderMedium } from './notification.model';
import { CustomQueryEncoderHelper } from '../data.model';

@Injectable()
export class NotificationService {

  constructor (
    private _httpClient: HttpClient,
    private _dataService: DataService
  ) {};

  getPreparedMessages(orgId: string): Observable<PreparedMessage[]> {
    return this._httpClient.get(`${NOTIFICATION_API_URL}/org/${orgId}/prepared_message`, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => response['data'].map((d: any) => new PreparedMessage(d))),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  sendPreparedMessage(orgId: string, caseLid: string, messageLid: string, follower_lid: string | undefined) {
    let preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        message_lid: messageLid
      }
    });
    if (follower_lid) preparedParams = preparedParams.set('follower_lid', follower_lid);

    return this._httpClient.post(`${NOTIFICATION_API_URL}/org/${orgId}/surgery/${caseLid}/prepared_message`, preparedParams, this._dataService.getApiRequestHeader(undefined, 'text'))
      .pipe(
        map((response: any) => 'success'),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  getCaseSmsFollowers(orgId: string, caseLid: string): Observable<GetSurgeryCaseSmsFollowersResponse> {
    return this._httpClient.get(`${NOTIFICATION_API_URL}/org/${orgId}/surgery/${caseLid}/sms_follower`, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new GetSurgeryCaseSmsFollowersResponse(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  AddCaseSmsFollower(orgLid: string, caseLid: string, phone: string, label: string, locale: string): Observable<CaseSmsFollower> {
    const preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        phone_number: phone,
        label: label,
        locale: locale,
      }
    });
    return this._httpClient.put(`${NOTIFICATION_API_URL}/org/${orgLid}/surgery/${caseLid}/sms_follower`, preparedParams, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new CaseSmsFollower(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  deleteCaseSmsFollower(orgLid: string, caseLid: string, followerLid: string) {
    return this._httpClient.delete(`${NOTIFICATION_API_URL}/org/${orgLid}/surgery/${caseLid}/sms_follower/${followerLid}`, this._dataService.getApiRequestHeader(undefined, 'text'))
      .pipe(
        map((response: any) => 'success'),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  addSurgeryBlockSubscriber(orgLid: string, blockLid: string, assignmentLid: string, date: string): Observable<SurgeryBlockSubscriber> {
    return this._httpClient.put(`${NOTIFICATION_API_URL}/org/${orgLid}/surgery/block/${blockLid}/assignment/${assignmentLid}/on/${date}`, null, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new SurgeryBlockSubscriber(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  updateSurgeryBlockSubscriber(orgLid: string, blockLid: string, assignmentLid: string, date: string, subscriptionLid: string, minAvailability: number): Observable<SurgeryBlockSubscriber> {
    const preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        min_availability: minAvailability.toString(),
      }
    });
    return this._httpClient.post(`${NOTIFICATION_API_URL}/org/${orgLid}/surgery/block/${blockLid}/assignment/${assignmentLid}/on/${date}/subscription/${subscriptionLid}`, preparedParams, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new SurgeryBlockSubscriber(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  deleteSurgeryBlockSubscriber(orgLid: string, blockLid: string, assignmentLid: string, date: string): Observable<string> {
    return this._httpClient.delete(`${NOTIFICATION_API_URL}/org/${orgLid}/surgery/block/${blockLid}/assignment/${assignmentLid}/on/${date}`, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => 'success'),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  addProviderMedium(orgLid: string, providerLid: string, mediumType: number, mediumAddress: string): Observable<ProviderMedium> {
    const preparedParams = new HttpParams({
      encoder: new CustomQueryEncoderHelper(),
      fromObject: {
        type: mediumType.toString(),
        address: mediumAddress
      }
    });

    return this._httpClient.put(`${NOTIFICATION_API_URL}/org/${orgLid}/provider/${providerLid}/medium`, preparedParams, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => new ProviderMedium(response['data'])),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  deleteProviderMedium(orgLid: string, providerLid: string, mediumLid: string): Observable<string> {
    return this._httpClient.delete(`${NOTIFICATION_API_URL}/org/${orgLid}/provider/${providerLid}/medium/${mediumLid}`, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => 'success'),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }

  getProviderMediums(orgLid: string, providerLid: string): Observable<Array<ProviderMedium>> {
    return this._httpClient.get(`${NOTIFICATION_API_URL}/org/${orgLid}/provider/${providerLid}/medium`, this._dataService.getApiRequestHeader())
      .pipe(
        map((response: any) => response['data'].map((m: any) => new ProviderMedium(m))),
        catchError(((error: HttpErrorResponse) => this._dataService.handleHttpError(error)))
      );
  }
}
