export class PreparedMessage {
  messageLid: string;
  orgLid: string;
  messageType: number;
  mediumType: number;
  body: string;

  constructor(data: any) {
    this.messageLid = data.message_lid;
    this.orgLid = data.org_lid;
    this.messageType = data.message_type;
    this.mediumType = data.medium_type;
    this.body = data.body;
  }
}